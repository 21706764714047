import { Component, OnInit } from '@angular/core';
import { EnvironmentSettingsService } from 'src/app/service/enviroment-settings.service';

@Component({
  selector: 'app-warrent-tech',
  templateUrl: './warrent-tech.component.html',
  styleUrls: ['./warrent-tech.component.scss']
})
export class WarrentTechComponent implements OnInit {

  imgPath = '';
  modalImg = '';
  playVideo = true;

  constructor(
    private envSettings: EnvironmentSettingsService
  ) { }

  ngOnInit() {
// determine image path
this.imgPath = this.envSettings.getServiceBase();

  }

  openModel(image) {
    this.modalImg = image;
    setTimeout(() => {
      document.getElementById('ticketModal').style.display = 'block';
    }, 500);
    
  }
}
