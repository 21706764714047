import { Component, OnInit } from '@angular/core';
import { EnvironmentSettingsService } from '../service/enviroment-settings.service';

@Component({
  selector: 'app-resume-page',
  templateUrl: './resume-page.component.html',
  styleUrls: ['./resume-page.component.scss']
})
export class ResumePageComponent implements OnInit {

  imgPath = '';
  pdfSrc = '';

  constructor(
    private envSettings: EnvironmentSettingsService
  ) { }


  ngOnInit() { 
    // determine image path
    this.imgPath = this.envSettings.getServiceBase();

    this.pdfSrc = this.imgPath + 'Casey_Karl_resume_missionStatement.pdf'

  }

  openShootSheet() {
    
  }

}
