import { Component, OnInit } from '@angular/core';

declare var require:any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openResumePage(){

    // const url = 'https://cpkrigging.com/resumePage.html';

    // window.open(url);

    const pdfUrl = './assets/images/Casey_Karl_resume_missionStatement.pdf';
    const pdfName = 'resume';
    FileSaver.saveAs(pdfUrl, pdfName);

  }

}
