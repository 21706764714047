import { Component, OnInit } from '@angular/core';
import { EnvironmentSettingsService } from '../service/enviroment-settings.service';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit {

  imgPath = '';
  modalImg = '';
  playVideo = true;
  modelName = '';

  constructor(
    private envSettings: EnvironmentSettingsService
  ) { }


  ngOnInit() {
    // determine image path
    this.imgPath = this.envSettings.getServiceBase();

  }

  openModel(image, name) {
    this.modalImg = image;
    this.modelName = name;
    setTimeout(() => {
      document.getElementById('ticketModal').style.display = 'block';
    }, 500);

  }

}
