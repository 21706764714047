import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentSettingsService } from '../service/enviroment-settings.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  
 imgPath = '';

 constructor(
   private router: Router,
   private envSettings: EnvironmentSettingsService
 ) { }

 ngOnInit() {
// determine image path
this.imgPath = this.envSettings.getServiceBase();

 }

 navTo(route){
   this.router.navigate([route]);
 }

}
